import React, { Component, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { Row, Col, Card, CardBody, Label, Input } from 'reactstrap';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { apiGet, apiPost } from '../../helpers/api_helper';
import SweetAlert from 'react-bootstrap-sweetalert';

const EventsEditor = () => {
  const history = useHistory();
  const location = useLocation();
  const [contentState, setContentState] = useState(EditorState.createEmpty());
  const [mailContentState, setMailContentState] = useState(EditorState.createEmpty());
  const [header, setHeader] = useState('');

  const [shouldAskForPayment, setShouldAskForPayment] = useState(false);
  const [shouldAskForAddress, setShouldAskForAddress] = useState(false);
  const [shouldAskForPhone, setShouldAskForPhone] = useState(false);
  const [shouldSendMail, setShouldSendMail] = useState(false);

  const [slug, setSlug] = useState('');
  const [mailHeader, setMailHeader] = useState('');
  const [summary, setSummary] = useState('');
  const [sweetAlert, setSweetAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState();


  useEffect(async () => {
    let html = ' "<p>Hey this <strong>editor</strong> rocks 😀</p>"';
    let htmlMail = ' "<p>Hey this <strong>editor</strong> rocks 😀</p>"';
    if (location.state && location.state.id) {
      const { data: events } = await apiGet(
        `events/${location.state.id}`,
      );
      html = events.text;
      htmlMail = events.mailContent;

      setHeader(events.header);
      setMailHeader(events.mailHeader);
      setSummary(events.summary);
      setSlug(events.slug);
      setShouldSendMail(events.shouldSendMail);
      setShouldAskForPayment(events.shouldAskForPayment);
      setShouldAskForAddress(events.shouldAskForAddress);
    }
    updateEditor(html);
    updateMailEditor(htmlMail);

  }, [0]);

  const onSuccess = () => {
    setSweetAlert(false);
    history.push('/forms');
  };

  const updateEditor = html => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const editorState = EditorState.createWithContent(contentState);
      setContentState(editorState);
    }
  };

  const updateMailEditor = html => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const editorState = EditorState.createWithContent(contentState);
      setMailContentState(editorState);
    }
  };

  const savePost = () => {
    const data = {
      header,
      mailHeader,
      mailContent: draftToHtml(convertToRaw(mailContentState.getCurrentContent())),
      text: draftToHtml(convertToRaw(contentState.getCurrentContent())),
      slug,
      shouldSendMail,
      shouldAskForPayment,
      shouldAskForAddress,
    };

    if (location.state && location.state.id) {
      data._id = location.state.id;
    }
    apiPost('events/save', data, true)
      .then(() => setSweetAlert(true))
      .catch(e => setErrorAlert(e.response.data));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Esnek Beyin / Etkinlik</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Etkinlik Detayları" />

          <Row className="mb-3">
            <Col xs={12} md={4}>
              <Label>Başlık</Label>
              <Input
                name="header"
                value={header}
                className="form-control"
                placeholder="Bir başlık giriniz"
                type="text"
                required
                onChange={e => setHeader(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={4}>
              <Label>E-Posta Başlığı</Label>
              <Input
                name="header"
                value={mailHeader}
                className="form-control"
                placeholder="Bir başlık giriniz"
                type="text"
                required
                onChange={e => setMailHeader(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={4}>
              <Label>Uzantı</Label>
              <Input
                name="slug"
                value={slug}
                className="form-control"
                placeholder="Eşsiz bir uzantı giriniz"
                type="text"
                required
                onChange={e => setSlug(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={3}>
              <Label>ÜCret Bilgisi Form' a Eklensin mi ?</Label>
              <div className="form-check form-switch form-switch-lg mb-3">
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizelg"
                  defaultChecked
                  checked={shouldAskForPayment}
                  onChange={() => setShouldAskForPayment(!shouldAskForPayment)}
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={3}>
              <Label>Adres Bilgisi Form' a Eklensin mi ?</Label>
              <div className="form-check form-switch form-switch-lg mb-3">
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizelg"
                  defaultChecked
                  checked={shouldAskForAddress}
                  onChange={() => setShouldAskForAddress(!shouldAskForAddress)}
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={3}>
              <Label>Telefon Form' a Eklensin mi ?</Label>
              <div className="form-check form-switch form-switch-lg mb-3">
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizelg"
                  defaultChecked
                  checked={shouldAskForPhone}
                  onChange={() => setShouldAskForPhone(!shouldAskForPhone)}
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={3}>
              <Label>E-Posta Gönderilsin mi ?</Label>
              <div className="form-check form-switch form-switch-lg mb-3">
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizelg"
                  defaultChecked
                  checked={shouldSendMail}
                  onChange={() => setShouldSendMail(!shouldSendMail)}
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Label>Etkinlik İçeriği</Label>
                  <Editor
                    editorState={contentState}
                    wrapperStyle={{ height: '450px' }}
                    editorStyle={{ height: '300px' }}
                    onEditorStateChange={editorState => {
                      setContentState(editorState);
                    }}
                  >
                    <textarea
                      disabled
                      value={draftToHtml(
                        convertToRaw(contentState.getCurrentContent()),
                      )}
                    />
                  </Editor>
                </CardBody>
              </Card>
            </Col>
          </Row>


          <Row className="mb-3">
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Label>E-Posta İçeriği</Label>
                  <Editor
                    editorState={mailContentState}
                    wrapperStyle={{ height: '350px' }}
                    editorStyle={{ height: '200px' }}
                    onEditorStateChange={editorState => {
                      setMailContentState(editorState);
                    }}
                  >
                    <textarea
                      disabled
                      value={draftToHtml(
                        convertToRaw(mailContentState.getCurrentContent()),
                      )}
                    />
                  </Editor>
                </CardBody>
              </Card>
            </Col>
          </Row>


          <Row className="mb-3">
            <Col xs={12} md={2}>
              <Input
                type="button"
                value="Kaydet"
                className="btn btn-success mb-3"
                onClick={savePost}
              />
            </Col>
          </Row>
        </div>
      </div>
      {sweetAlert ? (
        <SweetAlert
          title="Yazı kaydedildi!"
          timeout={2000}
          style={{
            position: 'absolute',
            top: '0px',
            right: '0px',
          }}
          showCloseButton={false}
          showConfirm={false}
          success
          onConfirm={onSuccess}
        ></SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert
          title={errorAlert.message}
          timeout={2000}
          style={{
            position: 'absolute',
            top: '0px',
            right: '0px',
          }}
          showCloseButton={false}
          showConfirm={false}
          error
          onConfirm={() => {
            setErrorAlert(undefined);
          }}
        ></SweetAlert>
      ) : null}
    </React.Fragment>
  );
};

export default EventsEditor;
