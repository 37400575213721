import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, Label, Badge, Modal } from 'reactstrap';
import MetaTags from 'react-meta-tags';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import '../../styles/datatables.scss';
import { apiGet } from 'helpers/api_helper';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

const EventsIndex = () => {
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [mdlInfoIsOpen, setInfoMdlIsOpen] = useState(false);
  const actionsBtnGroup = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Row className="mb-2">
          <Button
            className="btn btn-sm btn-dark"
            onClick={() => {
              apiGet(`events/attendees/${row._id}`).then(({ data }) => {
                console.log(data);
                setUserList(data);
                setInfoMdlIsOpen(true);
              });
            }}
          >
            Katılımcı Listesi
          </Button>
        </Row>
        <Row className="mb-2">
          <Button
            className="btn btn-sm btn-info"
            onClick={() => {
              history.push(`/forms/editor/${row._id}`, {
                id: row._id,
              });
            }}
          >
            Güncelle
          </Button>
        </Row>
        <Row className="mb-2">
          <Button
            className="btn btn-sm btn-success"
            onClick={() => {
              Swal.fire({
                title: 'Paylaş',
                icon: 'info',
                text: 'https://esnekbeyin.com/events/' + row.slug,
                confirmButtonText: 'Tamam',
              });
            }}
          >
            Paylaş
          </Button>
        </Row>
        {row.isActive === true &&
          <Row className="mb-2">
            <Button
              className="btn btn-sm btn-warning"
              onClick={() => {
                apiGet(`events/deactivate/${row._id}`).then(() => getEvents());
              }}
            >
              Yayından kaldır
            </Button>
          </Row>
        }
        {!row.isActive &&
          <Row className="mb-2">
            <Button
              className="btn btn-sm btn-success"
              onClick={() => {
                apiGet(`events/activate/${row._id}`).then(() => getEvents());
              }}
            >
              Yayınla
            </Button>
          </Row>
        }
      </>
    );
  };

  const getEvents = async () => {
    const { data } = await apiGet('events/for-admin', true);
    setTableData(data);
  };

  useEffect(getEvents, [0]);

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc',
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length,
    custom: true,
  };
  const columns = [
    {
      dataField: '_id',
      text: 'Id',
      sort: false,
    },
    {
      dataField: 'header',
      text: 'Başlık',
      sort: true,
    },
    {
      dataField: 'actions',
      text: 'İşlemler',
      formatter: actionsBtnGroup,
      sort: true,
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Esnek Beyin / Katılım Formları</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Katılım Formları" />

          <Row>
            <Col className="col-12">
              <a href="/forms/editor" className="btn btn-success w-sm mb-3">
                Yeni Form
              </a>
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={tableData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={tableData}
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={'id'}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={'table align-middle table-nowrap'}
                                    headerWrapperClasses={'thead-light'}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal
          size="xl"
          isOpen={mdlInfoIsOpen}
          toggle={() => setInfoMdlIsOpen(!mdlInfoIsOpen)}
        >
          <div className="modal-header">
            <h5
              className="modal-title mt-0 text-uppercase"
              id="myExtraLargeModalLabel"
            >
              Katılımcı Listesi
            </h5>
            <button
              onClick={() => setInfoMdlIsOpen(false)}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row text-center">
              <table className="table table-striped table-bordered table-hover table-sm">
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Adı</th>
                  <th scope="col">Telefon Numarası</th>
                  <th scope="col">E-posta</th>
                  <th scope="col">Ödeme Durumu</th>
                  <th scope="col">Ödeme Türü</th>
                  <th scope="col">Özel Mesajı</th>
                  <th scope="col">Adresi</th>
                </tr>
                </thead>
                <tbody>
                {userList.map((user, index) => {
                  return (<tr>
                    <th scope="row">{index + 1}</th>
                    <td>{user.applicantName}</td>
                    <td>{user.applicantPhone ? user.applicantPhone : '-'}</td>
                    <td>{user.applicantEmail}</td>
                    <td>{user.isPaid ? user.isPaid ? '✅' : '❌' : '-'}</td>
                    <td>{user.paymentOption ? user.paymentOption : '-'}</td>
                    <td>{user.applicantNote.length > 0 ? user.applicantNote : '--'}</td>
                    <td>{user.applicantAddress ? user.applicantAddress.slice(0, 30) + ' ...' : '-'} </td>
                  </tr>);
                })}
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col-md-3">
                <Button
                  onClick={() => setInfoMdlIsOpen(false)}
                  type="button"
                  className="btn btn-danger btn-sm"
                >
                  Kapat
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default EventsIndex;
