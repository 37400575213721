import React from 'react';
import { Redirect } from 'react-router-dom';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import BlogIndex from '../pages/Blog/index';
import Contact from '../pages/Dashboard/contact';
import Feedback from '../pages/Dashboard/feedback';
import BlogEditor from '../pages/Blog/editor';
import UserIndex from '../pages/User/index';
import QuizIndex from '../pages/Quiz/index';
import QuizEditor from '../pages/Quiz/editor';
import QuestionIndex from '../pages/Questions/index';
import QuestionEditor from '../pages/Questions/editor';
import AnxietyQuestionEditor from '../pages/Questions/anxiety-editor';
import AnxietyQuizEditor from '../pages/Quiz/anxiety-editor';
import FileIndex from '../pages/File/index';
import FileUpload from '../pages/File/upload';
import PackagesIndex from '../pages/Packages/index';
import PackagesEditor from '../pages/Packages/editor';
import OrdersIndex from '../pages/Orders/index';
import UserQuestionsIndex from '../pages/userQuestions/index';
import SessionIndex from '../pages/Sessions/index';
import SessionEditor from '../pages/Sessions/editor';

import EventsIndex from '../pages/Events/index';
import EventsEditor from '../pages/Events/editor';

// Dashboard
import Dashboard from '../pages/Dashboard/index';
import TotalResultQuestionEditor from '../pages/Questions/total-editor';
import TotalResultQuizEditor from '../pages/Quiz/total-result-editor';

const userRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/contact', component: Contact },
  { path: '/feedbacks', component: Feedback },
  { path: '/blog', component: BlogIndex },
  { path: '/blog/editor', component: BlogEditor },
  { path: '/blog/editor/:id', component: BlogEditor },
  { path: '/users', component: UserIndex },
  { path: '/quizes', component: QuizIndex },
  { path: '/files', component: FileIndex },
  { path: '/files/upload', component: FileUpload },
  { path: '/files/:packageId', component: FileIndex },
  { path: '/packages', component: PackagesIndex },
  { path: '/packages/editor', component: PackagesEditor },
  { path: '/sessions', component: SessionIndex },
  { path: '/sessions/editor/:id', component: SessionEditor },
  { path: '/packages/editor/:id', component: PackagesEditor },
  { path: '/quizes/editor', component: QuizEditor },
  { path: '/quizes/anxiety-editor', component: AnxietyQuizEditor },
  { path: '/quizes/total-result-editor', component: TotalResultQuizEditor },
  { path: '/questions', component: QuestionIndex },
  { path: '/questions/editor', component: QuestionEditor },
  { path: '/orders', component: OrdersIndex },
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: '/questions/anxiety-editor', component: AnxietyQuestionEditor },
  { path: '/questions/total-editor', component: TotalResultQuestionEditor },
  { path: '/user-questions/', component: UserQuestionsIndex },

  { path: '/forms', component: EventsIndex },
  { path: '/forms/editor', component: EventsEditor },
  { path: '/forms/editor/:id', component: EventsEditor },
];

const authRoutes = [{ path: '/login', component: Login }];

export { userRoutes, authRoutes };
