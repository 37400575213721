import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")}</li>

            <li>
              <Link to="/" className=" ">
                <i className="bx bx-home"></i>
                <span>{props.t("Anasayfa")}</span>
              </Link>
            </li>
            <li>
              <Link to="/calendar" className=" ">
                <i className="bx bx-calendar"></i>
                <span>{props.t("Takvim")}</span>
              </Link>
            </li>
            <li>
              <Link to="/forms" className=" ">
                <i className="bx bx-spa"></i>
                <span>{props.t("Formlar")}</span>
              </Link>
            </li>
            <li>
              <Link to="/Orders" className=" ">
                <i className="bx bx-receipt"></i>
                <span>{props.t("Siparişler")}</span>
              </Link>
            </li>
            <li>
              <Link to="/user-questions" className=" ">
                <i className="bx bx-star"></i>
                <span>{props.t("Ders Soruları")}</span>
              </Link>
            </li>
            <li>
              <Link to="/blog" className=" ">
                <i className="bx bx-book"></i>
                <span>{props.t("Blog")}</span>
              </Link>
            </li>
            <li>
              <Link to="/packages" className=" ">
                <i className="bx bx-package"></i>
                <span>{props.t("Eğitim Paketleri")}</span>
              </Link>
            </li>
            <li>
              <Link to="/sessions" className=" ">
                <i className="bx bx-tv"></i>
                <span>{props.t("Seanslar")}</span>
              </Link>
            </li>
            <li>
              <Link to="/files" className=" ">
                <i className="bx bx-video"></i>
                <span>{props.t("Dosyalar")}</span>
              </Link>
            </li>

            <li>
              <Link to="/contact" className=" ">
                <i className="bx bx-envelope"></i>
                <span>{props.t("Mesajlar")}</span>
              </Link>
            </li>

            <li>
              <Link to="/feedbacks" className=" ">
                <i className="bx bx-notification"></i>
                <span>{props.t("Geri Bildirimler")}</span>
              </Link>
            </li>

            <li>
              <Link to="/users" className=" ">
                <i className="bx bx-user"></i>
                <span>{props.t("Kullanıcılar")}</span>
              </Link>
            </li>
            <li>
              <Link to="/quizes" className=" ">
                <i className="bx bx-receipt"></i>
                <span>{props.t("Sınavlar")}</span>
              </Link>
            </li>
            <li>
              <Link to="/questions" className=" ">
                <i className="bx bx-question-mark"></i>
                <span>{props.t("Sorular")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
